import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';

const SidebarWrapper = styled.div`
  width: 200px;
  height: 100%;
  background: var(--color-bg-sidebar);
  box-shadow: 2px 0px 4px rgba(119, 119, 124, 0.15);
  z-index: 9;
  padding: 36px 0px;

  .menu-item {
    position: relative;
    width: 100%;
    height: 47px;
    margin-bottom: 16px;

    i {
      font-size: 20px;
      margin-right: 19px;
      margin-left: 27px;
      color: var(--color-text-secondary);
    }
    span {
      font-weight: 600;
      font-size: 15px;
      color: var(--color-text-secondary);
    }
  }
  .menu-item-active {
    &:before {
      content: ' ';
      position: absolute;
      width: 5px;
      height: 100%;
      left: 0;
      top: 0;
      background: var(--color-text-main);
    }

    i {
      color: var(--color-text-main);
    }

    span {
      color: var(--color-text-main);
    }
  }
`;

function Sidebar() {
  return (
    <SidebarWrapper className="scrollbar">
      <NavLink
        to="/uxa/users"
        className="flex align-center pointer menu-item"
        activeClassName="menu-item-active"
      >
        <Icon type="user" />
        <FormattedMessage id="Users" />
      </NavLink>
      <NavLink
        to="/uxa/products"
        className="flex align-center pointer menu-item"
        activeClassName="menu-item-active"
      >
        <Icon type="table" />
        <FormattedMessage id="Products" />
      </NavLink>
    </SidebarWrapper>
  );
}

export default Sidebar;
