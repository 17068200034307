import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import UsersContainer from 'containers/Main/Users';
import ProductsContainer from 'containers/Main/Products';

class RoutesContainer extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/uxa/users" component={UsersContainer} />
        <Route exact path="/uxa/products" component={ProductsContainer} />
      </Switch>
    );
  }
}

export default RoutesContainer;
