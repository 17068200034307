import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Icon } from 'antd';
import { connectAuth, authActionCreators } from 'core';
import { promisify } from 'utilities';

const HeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: #333;
  box-shadow: 0px 2px 4px rgba(119, 119, 124, 0.15);
  z-index: 10;
  padding: 9px 24px;
`;

const RightMenuWrapper = styled.div`
  color: var(--color-text-main);

  span {
    font-size: 15px;
    margin-left: 8px;
  }

  i {
    margin-left: 20px;
  }
`;

function Header({ logOut }) {
  const handleLogout = () => {
    promisify(logOut, {})
      .then(() => {})
      .catch(() => {});
  };

  return (
    <HeaderWrapper className="flex align-center just-end">
      <RightMenuWrapper
        className="flex align-center pointer"
        onClick={handleLogout}
      >
        Log out
        <Icon type="logout" />
      </RightMenuWrapper>
    </HeaderWrapper>
  );
}

Header.propTypes = {
  // intl: intlShape.isRequired,
  // history: PropTypes.object,
  logOut: PropTypes.func.isRequired
};

Header.defaultProps = {
  // history: {}
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapDispatchToProps = dispatch => {
  const { logOut } = authActionCreators;
  return bindActionCreators(
    {
      logOut
    },
    dispatch
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectAuth(mapStateToProps, mapDispatchToProps)
)(Header);
