import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { upperCase } from 'lodash';

import MainLayout from 'containers/Layout/MainLayout';
import { restService } from 'utilities/restService';

const { Search } = Input;

const UsersContainer = ({ accessToken }) => {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const loadUsers = async (p = 1) => {
    try {
      const response = await restService({
        api: `/admin/users?page=${p}&query=${query}`,
        method: 'GET',
        token: accessToken
      });
      if (response.status !== 200 || !response.data) {
        return;
      }

      setRows(response.data.rows || []);
      setTotal(response.data.count || 0);
    } catch (e) {
      // continue regardless of error
    }
  };

  const deleteUser = async userId => {
    try {
      const response = await restService({
        api: `/admin/users/${userId}`,
        method: 'DELETE',
        token: accessToken
      });
      if (response.status !== 200) {
        return;
      }

      loadUsers(page);
    } catch (e) {
      // continue regardless of error
    }
  };

  const handleChange = pagination => {
    setPage(pagination.current);
    loadUsers(pagination.current);
  };

  const handleChangeQuery = value => {
    setQuery(value);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Registered At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => moment(text).format('YYYY-MM-DD hh:mm:ss')
    },
    {
      title: 'Registered by',
      dataIndex: 'provider',
      key: 'provider',
      render: text => upperCase(text)
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <button type="submit" onClick={() => deleteUser(record.id)}>
          Remove
        </button>
      )
    }
  ];

  useEffect(() => {
    loadUsers();
  }, [query]);

  return (
    <MainLayout>
      <Search placeholder="Enter search text" onSearch={handleChangeQuery} />
      <Table
        dataSource={rows}
        pagination={{ pageSize: 20, total }}
        onChange={handleChange}
        columns={columns}
      />
    </MainLayout>
  );
};

UsersContainer.propTypes = {
  accessToken: PropTypes.string
};

UsersContainer.defaultProps = {
  accessToken: ''
};

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

export default connect(mapStateToProps)(UsersContainer);
