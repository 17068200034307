import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button } from 'antd';
import { connectAuth, authActionCreators } from 'core';
import { promisify } from 'utilities';

const LoginWrapper = styled.div`
  width: 450px;
  height: 100%;
  margin: 100px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 5px 13px rgba(190, 216, 222, 0.4);
  border-radius: 10px;

  .title {
    font-size: 24px;
    color: var(--color-black);
    font-weight: bold;
  }
`;

function LoginContainer({ form, history, login }) {
  const [errMsg, setErrMsg] = useState('');
  const { getFieldDecorator } = form;

  // useEffect(() => {
  //   if (user) {
  //     history.push('/uxa/users');
  //   }
  // }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        promisify(login, {
          username: values.username,
          password: values.password
        })
          .then(() => {
            setErrMsg('');
            history.push('/uxa/users');
          })
          .catch(() => {
            setErrMsg('Username or Password is incorrect!');
          });
      }
    });
  };

  return (
    <LoginWrapper className="scrollbar">
      <p className="center title">Login</p>
      <Form onSubmit={handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: []
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: []
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        {errMsg && <p className="error-msg">{errMsg}</p>}
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form>
    </LoginWrapper>
  );
}

LoginContainer.propTypes = {
  history: PropTypes.object,
  // user: PropTypes.object,
  form: PropTypes.object,
  login: PropTypes.func.isRequired
};

LoginContainer.defaultProps = {
  history: {},
  form: {}
  // user: {}
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapDispatchToProps = dispatch => {
  const { login } = authActionCreators;
  return bindActionCreators(
    {
      login
    },
    dispatch
  );
};

export default compose(
  withRouter,
  Form.create({ name: 'login_form' }),
  connectAuth(mapStateToProps, mapDispatchToProps)
)(LoginContainer);
