import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connectAuth } from 'core';

class PrivateRoute extends React.PureComponent {
  render() {
    const { accessToken, ...props } = this.props;
    if (accessToken) {
      return <Route {...props} />;
    }
    return <Redirect to="/" />;
  }
}

PrivateRoute.propTypes = {
  accessToken: PropTypes.string
};

PrivateRoute.defaultProps = {
  accessToken: ''
};

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken
});

export default connectAuth(mapStateToProps, {})(PrivateRoute);
